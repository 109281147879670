$(function () {
	let $popup = $('.js-popup');
	let $popupCloseBtn = $('.js-popup-close');
	let lastOpenedPopup = null;
	let scrollPosition;


	$('body').on('click','.js-popup-link',function(e){
		e.preventDefault();

		let $this = $(this);

		openPopup($this);

		return false;
	});



	function openPopup(thisel) {
		let target=thisel.attr('data-target');
		const $elem = $('.js-popup[data-block="'+target+'"]');
		$popup.removeClass('is-show');
		$elem.addClass('is-show');

		scrollPosition = $(window).scrollTop();

		if (thisel.attr('data-productname')) {
			$('.js-popup[data-block="'+target+'"]').find('.product-name').text(thisel.attr('data-productname'));
			$('.js-popup[data-block="'+target+'"]').find('input[name=productname]').val(thisel.attr('data-productname'));
		}




	}

	window.openPopup = openPopup;

	$popupCloseBtn.on('click',function(){
		let $this = $(this);
		let $popup = $this.closest('.js-popup');

		$popup.removeClass('is-show');
		$popup.find('.js-iframe').attr('src','');
		// location.hash = '';
		if (scrollPosition !== "undefined") {
			$(window).scrollTop(scrollPosition);
			scrollPosition = undefined;
		}


	});



});
